import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { getEnv } from '@/config/environment';

import type { AppError } from '@/models/error';

const HIDE_ERROR_AFTER_MS = 10000;

interface AppErrorState extends AppError {
    visible: boolean;
}

const logRawError = (error: AppError) => {
    console.group(`🚨 Error`);
    console.log('Type:', error.type);
    console.log('Severity:', error.severity);
    console.log('Message:', error.message);
    console.groupEnd();
};

// Create error state outside of the composable to make it global
const errorState = ref<AppErrorState>({
    type: 'UNKNOWN_ERROR',
    severity: 'error',
    visible: false,
    message: 'An unexpected error occurred',
});

let hideTimer: number | undefined;

const clearHideTimer = () => {
    if (hideTimer) {
        window.clearTimeout(hideTimer);
        hideTimer = undefined;
    }
};

const handleError = (error: AppError, visible: boolean = true): void => {
    clearHideTimer();
        
    if (getEnv() === 'local') {
        logRawError(error);
    }
    
    errorState.value = {
        type: error.type || 'UNKNOWN_ERROR',
        severity: error.severity || 'error',
        message: error.message,
        visible: visible,
    };

    if (error.type === 'UNKNOWN_ERROR') {
        // override message because it might be too technical
        errorState.value.message = 'An unexpected error occurred';
    }

    hideTimer = window.setTimeout(() => {
        errorState.value.visible = false;
        hideTimer = undefined;
    }, HIDE_ERROR_AFTER_MS);
};

const clearError = (): void => {
    clearHideTimer();
    errorState.value.visible = false;
};

// Create a global instance with basic error handling
const globalErrorHandler = {
    errorState,
    handleError,
    clearError,
};

// Setup function to be called after Vue is initialized
export const setupErrorHandler = () => {
    const router = useRouter();
    
    // Clear error on route change
    watch(
        () => router.currentRoute.value.fullPath,
        () => clearError()
    );
};

export default globalErrorHandler; 
