<template>
    <div id="home" class="container mx-auto px-4 lg:w-1/2 md:w-full">
        <SpinnerComponent v-if="loadingStore.isLoading"/>
        <template v-else>
            <div class="box text-center">
            <QueryPreview v-if="listing?.results.bounding_box" 
                :query="dataUrlService.createDataUrl(listing.query)"
                :bounding_box="listing.results.bounding_box"
            />
            </div>
            <ResultsListing v-if="listing" :items="listing.results.items"/>
        </template>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import SpinnerComponent from '@/components/common/SpinnerComponent.vue';
import QueryPreview from '@/components/search/QueryPreview.vue';
import ResultsListing from '@/components/search/ResultsListing.vue';

import searchService from '@/api/search.service';
import { useListingStore } from '@/stores/listing';
import { useLoadingStore } from '@/stores/loading';

import { useDataUrl } from '@/composables/useDataUrl';
import globalErrorHandler from '@/composables/useErrorHandler';

import type { Listing, Result } from '@/models/search';

const route = useRoute();
const router = useRouter();
const listingStore = useListingStore();
const loadingStore = useLoadingStore();
const dataUrlService = useDataUrl();

const getListing = async (listingId: string): Promise<void> => {
    try {
        loadingStore.startLoading();
        const result: Result<Listing> = await searchService.getListing(listingId);

        if (!result.success) {
            throw result.error;
        }

        listingStore.setListing(listingId, result.data);
    } catch (error) {
        globalErrorHandler.handleError(error as AppError, false);
        router.push('/404');
    } finally {
        loadingStore.stopLoading();
    }
};

const listing = computed<Listing | undefined>(() => {
    const listingId = route.params.listingId as string;
    const existingListing = listingStore.getListing(listingId);

    if (!existingListing) {
        void getListing(listingId);
    }

    return existingListing;
});
</script>
