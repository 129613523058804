import axios, { AxiosError, AxiosResponse } from 'axios';

import { getBackendUrl } from '@/config/environment';

import type { AppError } from '@/models/error';

const api = axios.create({
    baseURL: getBackendUrl(),
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
});

interface ErrorResponse {
    errors?: Record<string, string[]>;
    message?: string;
}

export type Result<T> = {
    success: true;
    data: T;
} | {
    success: false;
    error: AppError;
};

const createError = (error: AxiosError<ErrorResponse>): AppError => {
    // Server responded with error status
    if (error.response) {
        return {
            type: 'SERVER_ERROR',
            severity: 'error',
            message: error.response.data?.message || 'Unknown server error occurred',
        } as AppError;
    }

    // Request was made but no response received
    if (error.request) {
        return {
            type: 'NETWORK_ERROR',
            severity: 'error',
            message: 'Unable to connect to the server. Please check your internet connection.',
        } as AppError;
    }
    
    // Something else went wrong
    return {
        type: 'UNKNOWN_ERROR',
        severity: 'error',
    } as AppError;
};

// Define custom axios instance that returns Result
type CustomAxiosInstance = typeof api;

// Override axios response type
const successInterceptor = <T>(response: AxiosResponse<T>): Promise<Result<T>> => {
    return Promise.resolve({
        success: true,
        data: response.data,
    });
};

const errorInterceptor = (error: AxiosError<ErrorResponse>): Promise<Result<never>> => {
    const appError = createError(error);
    
    return Promise.resolve({
        success: false,
        error: appError,
    });
};

// Use type assertion to bypass axios's strict typing
api.interceptors.response.use(
    successInterceptor as any,
    errorInterceptor as any
);

export default api as CustomAxiosInstance;
