<template>
  <Transition name="fade">
    <div v-if="errorState.visible" 
         :class="[
           'fixed top-0 left-0 right-0 p-6 shadow-lg z-50 text-center',
           errorState.severity === 'error' ? 'bg-red-500 text-white' :
           errorState.severity === 'warning' ? 'bg-yellow-50 text-yellow-900' :
           'bg-blue-50 text-blue-900'
         ]"
    >
      <div class="flex items-center justify-center space-x-4 max-w-7xl mx-auto">
        <div class="flex-1">
          <p class="text-base font-medium">{{ errorState.message }}</p>
        </div>
        <div class="flex-shrink-0">
          <button @click="clearError" 
                  class="inline-flex p-1.5 rounded-full transition-colors"
                  :class="[
                    errorState.severity === 'error' ? 'text-white hover:bg-red-400' :
                    errorState.severity === 'warning' ? 'text-yellow-500 hover:text-yellow-600' :
                    'text-blue-500 hover:text-blue-600'
                  ]">
            <span class="sr-only">Close</span>
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import globalErrorHandler from '@/composables/useErrorHandler';

const { errorState, clearError } = globalErrorHandler;

</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-5rem);
}
</style> 
