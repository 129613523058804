import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "group w-auto border border-gray-300 hover:shadow-lg rounded my-4 bg-white" }
const _hoisted_3 = { class: "border-b border-gray-300 py-4 px-2" }
const _hoisted_4 = { class: "text-xl" }
const _hoisted_5 = { class: "flex flex-wrap justify-center items-center space-x-2 mt-2" }
const _hoisted_6 = { class: "text-gray-600 text-lg" }
const _hoisted_7 = ["src"]

import { useRoute } from 'vue-router';

import searchService from '@/api/search.service';
import CopyIcon from '@/icons/CopyIcon.vue';

import { useClipboard } from '@/composables/useClipboard';
import globalErrorHandler from '@/composables/useErrorHandler';
import { useImage } from '@/composables/useImage';

import type { FeedbackPayload, FeedbackSource } from '@/models/feedback';
import type { Item } from '@/models/item';

interface ResultItemProps extends Item {
    item_rank: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ResultItem',
  props: {
    item_rank: {},
    id: {},
    name: {},
    img_url: {},
    external_sites: {},
    category: {},
    type: {},
    score: {}
  },
  setup(__props: any) {

const props = __props;

const route = useRoute();
const { isActive, copyToClipboard } = useClipboard();
const { replaceBrokenImage } = useImage();

const sendFeedback = async (source: FeedbackSource): Promise<void> => {
    const payload: FeedbackPayload = {
        listing_id: route.params.listingId as string,
        item_id: props.id,
        item_type: props.type,
        is_prediction_correct: true,
        source: source,
        item_rank: props.item_rank
    };

    try {
        const result = await searchService.submitFeedback(payload);
        if (!result.success) {
            throw result.error;
        }
    } catch (error) {
        globalErrorHandler.handleError(error as AppError, false);
    }
};

const copyItemId = async (): Promise<void> => {
    await copyToClipboard(props.id);
    await sendFeedback('copy-btn');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    class: "min-w-max text-center",
    href: _ctx.external_sites[0].url,
    target: "blank",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (sendFeedback('external-site')))
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.name), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, "Bricklink ID: " + _toDisplayString(_ctx.id), 1),
          _createVNode(CopyIcon, {
            onClick: _withModifiers(copyItemId, ["stop","prevent"]),
            class: _normalizeClass(["text-xl", { 'text-red-500': _unref(isActive), 'text-gray-600': !_unref(isActive), 'hover:text-gray-800': !_unref(isActive)}])
          }, null, 8, ["class"])
        ])
      ]),
      _createElementVNode("img", {
        class: "p-4 inline mx-auto",
        src: _ctx.img_url,
        onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(replaceBrokenImage) && _unref(replaceBrokenImage)(...args)))
      }, null, 40, _hoisted_7)
    ])
  ], 8, _hoisted_1))
}
}

})