<template>
    <SpinnerComponent v-if="loadingStore.isLoading"/>
    <canvas ref="canvas" class="hidden"></canvas>

    <div id="home" class="container mx-auto px-4 lg:w-1/2 md:w-full">
        <div class="box text-center">
            <div class="mb-4 space-y-4">
                <p>Brickognize is an image search app for Lego bricks.</p>
                <p>Currently, it recognizes only a single part, minifigure or set per image.</p>
                <p class="italic">This is still a beta version. Things might not work as expected. <a class="underline hover:text-gray-400" href="mailto:piotr.rybak@brickognize.com">Let me know</a> if that happens.</p>
            </div>
            <p class="text-2xl mb-2 mt-6">Search</p>
            <span title="upload image">
                <label for="search-input-home" class="btn w-24 bg-red-500 hover:bg-red-50 border-red-500"><UploadIcon class="inline-block w-6 h-6"/>upload</label>
                <input type="file" accept="image/*" @change="(event: Event) => predictFromUpload(event.target, canvas)" id="search-input-home" class="hidden">
            </span>
            <span v-if="hasCamera" title="take a photo">
                <span v-if="isMobile">
                    <label for="search-input-home-mobile" class="btn w-24 bg-yellow-500 hover:bg-yellow-50 border-yellow-500"><CameraIcon class="inline-block w-6 h-6"/>camera</label>
                    <input type="file" capture="environment" accept="image/*" @change="(event: Event) => predictFromUpload(event.target, canvas)" id="search-input-home-mobile" class="hidden">
                </span>
                <span v-else>
                    <router-link class="btn w-24 bg-yellow-500 hover:bg-yellow-50 border-yellow-500" to="/search"><CameraIcon class="inline-block w-6 h-6"/>camera</router-link>
                </span>
            </span>
            <span v-else title="camera not available">
                <div class="btn w-24 bg-gray-300 border-gray-300 text-gray-500 hover:text-gray-500 cursor-not-allowed"><CameraIcon class="inline-block w-6 h-6"/>camera</div>
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

import SpinnerComponent from '@/components/common/SpinnerComponent.vue'

import CameraIcon from '@/icons/CameraIcon.vue'
import UploadIcon from '@/icons/UploadIcon.vue'
import { useLoadingStore } from '@/stores/loading'

import camera from '@/composables/camera'
import { useSearch } from '@/composables/search'

const canvas = ref<HTMLCanvasElement | null>(null);
const hasCamera = ref<boolean>(true);
const isMobile = ref<boolean>(true);
const loadingStore = useLoadingStore();
const { predictFromUpload } = useSearch();

onMounted(() => {
    camera.setIsMobile(isMobile);
    camera.setHasCamera(hasCamera);
});
</script>
