import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center space-x-4 max-w-7xl mx-auto" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { class: "text-base font-medium" }
const _hoisted_4 = { class: "flex-shrink-0" }

import { computed } from 'vue';

import globalErrorHandler from '@/composables/useErrorHandler';


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorNotification',
  setup(__props) {

const { errorState, clearError } = globalErrorHandler;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_unref(errorState).visible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([
           'fixed top-0 left-0 right-0 p-6 shadow-lg z-50 text-center',
           _unref(errorState).severity === 'error' ? 'bg-red-500 text-white' :
           _unref(errorState).severity === 'warning' ? 'bg-yellow-50 text-yellow-900' :
           'bg-blue-50 text-blue-900'
         ])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(errorState).message), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(clearError) && _unref(clearError)(...args))),
                  class: _normalizeClass(["inline-flex p-1.5 rounded-full transition-colors", [
                    _unref(errorState).severity === 'error' ? 'text-white hover:bg-red-400' :
                    _unref(errorState).severity === 'warning' ? 'text-yellow-500 hover:text-yellow-600' :
                    'text-blue-500 hover:text-blue-600'
                  ]])
                }, _cache[1] || (_cache[1] = [
                  _createElementVNode("span", { class: "sr-only" }, "Close", -1),
                  _createElementVNode("svg", {
                    class: "h-5 w-5",
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 20 20",
                    fill: "currentColor"
                  }, [
                    _createElementVNode("path", {
                      "fill-rule": "evenodd",
                      d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                      "clip-rule": "evenodd"
                    })
                  ], -1)
                ]), 2)
              ])
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})