import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "fixed inset-0 z-30 bg-black flex items-center justify-center h-full overflow-hidden" }
const _hoisted_2 = { class: "absolute flex bottom-0 min-w-full bg-black bg-opacity-50 py-4 items-center justify-center space-x-12" }
const _hoisted_3 = {
  for: "search-input-search",
  class: "text-white inline-block cursor-pointer hover:text-gray-500",
  title: "upload image"
}

import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import SpinnerComponent from '@/components/common/SpinnerComponent.vue';

import BackIcon from '@/icons/BackIcon.vue';
import UploadIcon from '@/icons/UploadIcon.vue';
import { useLoadingStore } from '@/stores/loading';

import camera from '@/composables/camera';
import { useSearch } from '@/composables/search';



export default /*@__PURE__*/_defineComponent({
  __name: 'SearchView',
  setup(__props) {

const router = useRouter();
const video = ref<HTMLVideoElement | null>(null);
const canvas = ref<HTMLCanvasElement | null>(null);
const hasCamera = ref<boolean>(true);
const loadingStore = useLoadingStore();
const { predictFromVideo, predictFromUpload } = useSearch();

const handleKeys = async (e: KeyboardEvent): Promise<void> => {
    if (e.code === 'Space') {
        await predictFromVideo(video.value, canvas.value);
    }
};

onMounted(() => {
    if (video.value) {
        camera.startCamera(video.value, hasCamera);
    }
    window.addEventListener('keyup', handleKeys);
});

onBeforeUnmount(() => {
    if (video.value) {
        camera.stopCamera(video.value);
    }
    window.removeEventListener('keyup', handleKeys);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(loadingStore).isLoading)
      ? (_openBlock(), _createBlock(SpinnerComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("video", {
        ref_key: "video",
        ref: video,
        autoPlay: "{true}",
        playsInline: "{true}",
        muted: "{true}",
        class: "absolute w-auto min-w-full min-h-full max-w-none"
      }, null, 512),
      _withDirectives(_createElementVNode("canvas", {
        ref_key: "canvas",
        ref: canvas,
        class: "absolute w-auto min-w-full min-h-full max-w-none"
      }, null, 512), [
        [_vShow, _unref(loadingStore).isLoading]
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, [
          _createVNode(UploadIcon, { class: "w-8 h-8" })
        ]),
        _createElementVNode("input", {
          type: "file",
          accept: "image/*",
          onChange: _cache[0] || (_cache[0] = (event) => _unref(predictFromUpload)(event.target, canvas.value)),
          id: "search-input-search",
          class: "hidden"
        }, null, 32),
        (hasCamera.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[1] || (_cache[1] = () => _unref(predictFromVideo)(video.value, canvas.value)),
              class: "text-white inline-block cursor-pointer w-16 h-16 border-white border-2 rounded-full flex items-center justify-center",
              title: "take a photo"
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "inline-block bg-white w-14 h-14 rounded-full hover:bg-opacity-75" }, null, -1)
            ])))
          : _createCommentVNode("", true),
        _createVNode(_component_router_link, {
          class: "text-white inline-block cursor-pointer hover:text-gray-500",
          to: "/",
          title: "go back"
        }, {
          default: _withCtx(() => [
            _createVNode(BackIcon, { class: "w-8 h-8" })
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}
}

})