import posthog from 'posthog-js';
import { useRouter } from 'vue-router';

import searchService, { type Result } from '@/api/search.service';
import { useListingStore } from '@/stores/listing';
import { useLoadingStore } from '@/stores/loading';

import { useCanvas } from '@/composables/useCanvas';
import type { ImageMimeType, ImageSource } from '@/composables/useCanvas';
import { useDataUrl } from '@/composables/useDataUrl';
import globalErrorHandler from '@/composables/useErrorHandler';

import type { AppError } from '@/models/error';
import type { Listing, SearchResults } from '@/models/search';

interface SearchService {
    predictFromVideo: (video: HTMLVideoElement | null, canvas: HTMLCanvasElement | null) => Promise<void>;
    predictFromUpload: (input: HTMLInputElement, canvas: HTMLCanvasElement | null) => Promise<void>;
}

export const useSearch = (): SearchService => {
    const router = useRouter();
    const listingStore = useListingStore();
    const loadingStore = useLoadingStore();
    const dataUrlService = useDataUrl();
    const canvasService = useCanvas();

    const beforePredict = (): void => {
        loadingStore.startLoading();
        posthog.capture('search', { state: 'start', platform: 'web' });
    };

    const afterPredict = (): void => {
        loadingStore.stopLoading();
    };

    const processCanvasImage = (
        canvas: HTMLCanvasElement,
        source: ImageSource
    ): { dataURI: string; file: File } => {
        const downscaledCanvas = canvasService.createDownscaledCanvas(canvas, source);
        const dataURI = canvasService.toDataUrl(downscaledCanvas, source.fileType);
        const file = dataUrlService.createFile(dataURI, source.fileName);

        return { dataURI, file };
    };

    const getResultsRoute = (listing: Listing): string => {
        let route = `/results/${listing.id}`;
        if (listing.results.items.length > 0) {
            const firstItem = listing.results.items[0];
            route = `/results/${listing.id}/?item=${firstItem.type}/${firstItem.id}`;
        }
        return route;
    };

    const predictFromCanvas = async (
        canvas: HTMLCanvasElement,
        source: ImageSource
    ): Promise<void> => {
        beforePredict();

        try {
            const { dataURI, file } = processCanvasImage(canvas, source);
            const result: Result<SearchResults> = await searchService.predict(file);
            
            if (!result.success) {
                throw result.error;
            }
            
            const listing: Listing = {
                id: result.data.listing_id,
                results: result.data,
                query: dataUrlService.parseDataUrl(dataURI)
            };

            listingStore.setListing(listing.id, listing);
            router.push(getResultsRoute(listing));
        } catch (error) {
            posthog.capture('search', { state: 'error', platform: 'web' });
            globalErrorHandler.handleError(error as AppError);
        } finally {
            afterPredict();
        }
    };

    const createVideoSource = (video: HTMLVideoElement): ImageSource => ({
        element: video,
        width: video.videoWidth,
        height: video.videoHeight,
        fileName: 'image.jpg',
        fileType: 'image/jpeg'
    });

    const createImageUploadSource = (target: HTMLInputElement): Promise<ImageSource> => {
        if (!target.files?.length) {
            throw new Error('No file selected');
        }

        const imageFile = target.files[0];
        const imageUpload = document.createElement('img');
        imageUpload.src = URL.createObjectURL(imageFile);
        
        return new Promise((resolve) => {
            imageUpload.onload = () => {
                try {
                    resolve({
                        element: imageUpload,
                        width: imageUpload.width,
                        height: imageUpload.height,
                        fileName: imageFile.name,
                        fileType: imageFile.type as ImageMimeType
                    });
                } catch (error) {
                    globalErrorHandler.handleError({
                        type: 'CLIENT_ERROR',
                        severity: 'error',
                        message: 'Failed to process the image'
                    });
                }
            };

            imageUpload.onerror = () => {
                globalErrorHandler.handleError({
                    type: 'CLIENT_ERROR',
                    severity: 'error',
                    message: 'Failed to load the image'
                });
            };
        });
    };

    const predictFromVideo = async (video: HTMLVideoElement | null, canvas: HTMLCanvasElement | null): Promise<void> => {
        if (!video || !canvas) return;
        await predictFromCanvas(canvas, createVideoSource(video));
    };

    const predictFromUpload = async (input: HTMLInputElement, canvas: HTMLCanvasElement | null): Promise<void> => {
        if (!input || !canvas) return;
        await predictFromCanvas(canvas, await createImageUploadSource(input));
    };

    return {
        predictFromVideo,
        predictFromUpload
    };
};

export type { SearchService };
