import { Ref } from 'vue'

import globalErrorHandler from '@/composables/useErrorHandler';

import type { AppError } from '@/models/error';

interface CameraState {
    isMobile: Ref<boolean>;
    hasCamera: Ref<boolean>;
}

interface CameraService {
    setIsMobile(isMobile: Ref<boolean>): void;
    setHasCamera(hasCamera: Ref<boolean>): Promise<void>;
    startCamera(video: HTMLVideoElement, hasCamera: Ref<boolean>): Promise<void>;
    stopCamera(video: HTMLVideoElement): void;
}

const camera: CameraService = {
    setIsMobile(isMobile: Ref<boolean>): void {
        const el = document.createElement('input');
        isMobile.value = el.capture !== undefined;
    },

    async setHasCamera(hasCamera: Ref<boolean>): Promise<void> {
        if (!navigator.mediaDevices?.enumerateDevices) {
            hasCamera.value = false;
            return;
        }

        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            hasCamera.value = devices.some(device => device.kind === 'videoinput');
        } catch (err) {
            hasCamera.value = false;
            return;
        }
    },

    async startCamera(video: HTMLVideoElement, hasCamera: Ref<boolean>): Promise<void> {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ 
                video: { facingMode: 'environment' } 
            });
            video.srcObject = stream;
            await video.play();
        } catch (rawError) {
            let error: AppError;
            
            if (rawError instanceof Error) {
                if (rawError.name === 'NotAllowedError' || rawError.name === 'PermissionDeniedError') {
                    error = {
                        type: 'CAMERA_ERROR',
                        severity: 'error',
                        message: 'Please allow camera access in your browser settings'
                    };
                } else if (rawError.name === 'NotFoundError' || rawError.name === 'DevicesNotFoundError') {
                    error = {
                        type: 'CAMERA_ERROR',
                        severity: 'error',
                        message: 'No camera found on your device'
                    };
                } else {
                    error = {
                        type: 'CAMERA_ERROR',
                        severity: 'error',
                        message: `Camera error: ${rawError.message}`
                    };
                }
            } else {
                error = {
                    type: 'CAMERA_ERROR',
                    severity: 'error',
                    message: 'Failed to access camera'
                };
            }
            hasCamera.value = false;
            globalErrorHandler.handleError(error);
        }
    },

    stopCamera(video: HTMLVideoElement): void {
        const stream = video.srcObject as MediaStream | null;
        stream?.getTracks().forEach(track => track.stop());
        video.srcObject = null;
    }
};

export type { CameraState, CameraService };
export default camera
