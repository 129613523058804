import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "fixed w-full h-16 z-20 top-0 left-0 bg-white border-b border-gray-300 px-2 sm:px-4 py-3" }
const _hoisted_2 = { class: "container flex flex-wrap items-center justify-between mx-auto lg:w-1/2 md:w-full" }
const _hoisted_3 = {
  class: "w-auto",
  id: "navbar-default"
}
const _hoisted_4 = { class: "flex flex-row pr-2 sm:pr-4 lg:pr-2 mt-0 space-x-8 text-gray-600 hover:text-gray-800" }
const _hoisted_5 = {
  class: "block hover:text-gray-400",
  title: "upload image"
}
const _hoisted_6 = {
  for: "search-input-navbar",
  class: "block cursor-pointer hover:text-gray-400"
}
const _hoisted_7 = {
  key: 0,
  class: "block hover:text-gray-400 pr-1",
  title: "take a photo"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  for: "search-input-navbar-mobile",
  class: "block cursor-pointer hover:text-gray-400"
}
const _hoisted_10 = { key: 1 }

import { onMounted, ref } from 'vue';

import MainLogo from '@/components/common/MainLogo.vue';
import SpinnerComponent from '@/components/common/SpinnerComponent.vue';

import CameraIcon from '@/icons/CameraIcon.vue';
import UploadIcon from '@/icons/UploadIcon.vue';
import { useLoadingStore } from '@/stores/loading';

import camera from '@/composables/camera';
import { useSearch } from '@/composables/search';



export default /*@__PURE__*/_defineComponent({
  __name: 'NavBar',
  setup(__props) {

const canvas = ref<HTMLCanvasElement | null>(null);
const hasCamera = ref<boolean>(true);
const isMobile = ref<boolean>(true);
const loadingStore = useLoadingStore();
const { predictFromUpload } = useSearch();

onMounted(() => {
    camera.setIsMobile(isMobile);
    camera.setHasCamera(hasCamera);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(loadingStore).isLoading)
      ? (_openBlock(), _createBlock(SpinnerComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("canvas", {
      ref_key: "canvas",
      ref: canvas,
      class: "hidden"
    }, null, 512),
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "pl-2 sm:pl-4 lg:pl-2"
        }, {
          default: _withCtx(() => [
            _createVNode(MainLogo, { class: "inline-block h-10 pl-1" })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, [
                _createVNode(UploadIcon, { class: "inline-block w-6 h-6" })
              ]),
              _createElementVNode("input", {
                type: "file",
                accept: "image/*",
                onChange: _cache[0] || (_cache[0] = (event) => _unref(predictFromUpload)(event.target, canvas.value)),
                id: "search-input-navbar",
                class: "hidden"
              }, null, 32)
            ]),
            (hasCamera.value)
              ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                  (isMobile.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                        _createElementVNode("label", _hoisted_9, [
                          _createVNode(CameraIcon, { class: "inline-block w-6 h-6" })
                        ]),
                        _createElementVNode("input", {
                          type: "file",
                          capture: "environment",
                          accept: "image/*",
                          onChange: _cache[1] || (_cache[1] = (event) => _unref(predictFromUpload)(event.target, canvas.value)),
                          id: "search-input-navbar-mobile",
                          class: "hidden"
                        }, null, 32)
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_10, [
                        _createVNode(_component_router_link, { to: "/search" }, {
                          default: _withCtx(() => [
                            _createVNode(CameraIcon, { class: "inline-block w-6 h-6" })
                          ]),
                          _: 1
                        })
                      ]))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ], 64))
}
}

})