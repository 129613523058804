type ImageMimeType = 'image/jpeg' | 'image/png' | 'image/webp';

const DEFAULT_MAX_SIZE = 1024;
const DEFAULT_QUALITY = 0.95;

interface ImageSource {
    element: HTMLImageElement | HTMLVideoElement;
    width: number;
    height: number;
    fileName: string;
    fileType: ImageMimeType;
}

export const useCanvas = () => {
    const calculateScaleProportions = (width: number, height: number, maxSize: number = DEFAULT_MAX_SIZE): number => {
        if (width <= maxSize && height <= maxSize) return 1;
        return height <= width ? maxSize / width : maxSize / height;
    };

    const createDownscaledCanvas = (
        canvas: HTMLCanvasElement,
        source: ImageSource,
        maxSize: number = DEFAULT_MAX_SIZE
    ): HTMLCanvasElement => {
        const context = canvas.getContext('2d', { alpha: true });
        if (!context) throw new Error('Could not get canvas context');

        const scaleProportions = calculateScaleProportions(source.width, source.height, maxSize);
        canvas.width = source.width * scaleProportions;
        canvas.height = source.height * scaleProportions;
        context.drawImage(source.element, 0, 0, canvas.width, canvas.height);

        return canvas;
    };

    const toDataUrl = (canvas: HTMLCanvasElement, type: ImageMimeType, quality: number = DEFAULT_QUALITY): string => 
        canvas.toDataURL(type, quality);

    return {
        createDownscaledCanvas,
        toDataUrl
    };
};

export type { ImageSource, ImageMimeType };
export type CanvasService = ReturnType<typeof useCanvas>;
