import api, { type Result } from './axios';

import type { FeedbackPayload } from '@/models/feedback';
import type { Listing, SearchResults } from '@/models/search';


class SearchService {
    async predict(file: File): Promise<Result<SearchResults>> {
        const data = new FormData();
        data.append('query_image', file);
        
        return api.post('/predict/', data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    }

    async submitFeedback(feedback: FeedbackPayload): Promise<Result<void>> {
        return api.post('/feedback/', feedback);
    }

    async getListing(id: string): Promise<Result<Listing>> {
        return api.get(`/listing/${id}`);
    }
}

export default new SearchService();
export type { Result };
