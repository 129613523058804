<template>
  <metainfo></metainfo>
  <div id="app" class="flex flex-col h-screen text-gray-800">
    <NavBar/>
    <div class="flex-grow bg-gray-50 mt-16">
      <router-view/>
    </div>
    <FooterComponent/>
    <ErrorNotification />
  </div>
</template>

<script setup lang="ts">
import { onErrorCaptured, onMounted, onUnmounted } from 'vue';
import { useMeta } from 'vue-meta';
import { useRouter } from 'vue-router';

import ErrorNotification from '@/components/common/ErrorNotification.vue';
import FooterComponent from '@/components/layout/FooterComponent.vue';
import NavBar from '@/components/layout/NavBar.vue';

import globalErrorHandler, { setupErrorHandler } from '@/composables/useErrorHandler';

import type { AppError } from '@/models/error';

const router = useRouter();

setupErrorHandler();

const handleKeys = (e: KeyboardEvent): void => {
  if (e.code === 'Backspace') {
    router.back();
  }
};

useMeta({
  htmlAttrs: { lang: 'en' },
  description: { content: 'Brickognize is an image search for LEGO bricks!' },
});

onMounted(() => {
  window.addEventListener('keyup', handleKeys);
});

onUnmounted(() => {
  window.removeEventListener('keyup', handleKeys);
});

// Handle all uncaught errors in components
onErrorCaptured((error) => {
  const appError: AppError = {
    type: 'UNKNOWN_ERROR',
    severity: 'error'
  };

  if (error instanceof Error) {
    appError.message = error.message;
  }

  globalErrorHandler.handleError(appError);
  return false;
});
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
