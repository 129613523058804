import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "home",
  class: "container mx-auto px-4 lg:w-1/2 md:w-full"
}
const _hoisted_2 = { class: "box text-center" }
const _hoisted_3 = { title: "upload image" }
const _hoisted_4 = {
  for: "search-input-home",
  class: "btn w-24 bg-red-500 hover:bg-red-50 border-red-500"
}
const _hoisted_5 = {
  key: 0,
  title: "take a photo"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  for: "search-input-home-mobile",
  class: "btn w-24 bg-yellow-500 hover:bg-yellow-50 border-yellow-500"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 1,
  title: "camera not available"
}
const _hoisted_10 = { class: "btn w-24 bg-gray-300 border-gray-300 text-gray-500 hover:text-gray-500 cursor-not-allowed" }

import { onMounted, ref } from 'vue'

import SpinnerComponent from '@/components/common/SpinnerComponent.vue'

import CameraIcon from '@/icons/CameraIcon.vue'
import UploadIcon from '@/icons/UploadIcon.vue'
import { useLoadingStore } from '@/stores/loading'

import camera from '@/composables/camera'
import { useSearch } from '@/composables/search'


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const canvas = ref<HTMLCanvasElement | null>(null);
const hasCamera = ref<boolean>(true);
const isMobile = ref<boolean>(true);
const loadingStore = useLoadingStore();
const { predictFromUpload } = useSearch();

onMounted(() => {
    camera.setIsMobile(isMobile);
    camera.setHasCamera(hasCamera);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(loadingStore).isLoading)
      ? (_openBlock(), _createBlock(SpinnerComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("canvas", {
      ref_key: "canvas",
      ref: canvas,
      class: "hidden"
    }, null, 512),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mb-4 space-y-4" }, [
          _createElementVNode("p", null, "Brickognize is an image search app for Lego bricks."),
          _createElementVNode("p", null, "Currently, it recognizes only a single part, minifigure or set per image."),
          _createElementVNode("p", { class: "italic" }, [
            _createTextVNode("This is still a beta version. Things might not work as expected. "),
            _createElementVNode("a", {
              class: "underline hover:text-gray-400",
              href: "mailto:piotr.rybak@brickognize.com"
            }, "Let me know"),
            _createTextVNode(" if that happens.")
          ])
        ], -1)),
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-2xl mb-2 mt-6" }, "Search", -1)),
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, [
            _createVNode(UploadIcon, { class: "inline-block w-6 h-6" }),
            _cache[2] || (_cache[2] = _createTextVNode("upload"))
          ]),
          _createElementVNode("input", {
            type: "file",
            accept: "image/*",
            onChange: _cache[0] || (_cache[0] = (event) => _unref(predictFromUpload)(event.target, canvas.value)),
            id: "search-input-home",
            class: "hidden"
          }, null, 32)
        ]),
        (hasCamera.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              (isMobile.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    _createElementVNode("label", _hoisted_7, [
                      _createVNode(CameraIcon, { class: "inline-block w-6 h-6" }),
                      _cache[3] || (_cache[3] = _createTextVNode("camera"))
                    ]),
                    _createElementVNode("input", {
                      type: "file",
                      capture: "environment",
                      accept: "image/*",
                      onChange: _cache[1] || (_cache[1] = (event) => _unref(predictFromUpload)(event.target, canvas.value)),
                      id: "search-input-home-mobile",
                      class: "hidden"
                    }, null, 32)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, [
                    _createVNode(_component_router_link, {
                      class: "btn w-24 bg-yellow-500 hover:bg-yellow-50 border-yellow-500",
                      to: "/search"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(CameraIcon, { class: "inline-block w-6 h-6" }),
                        _cache[4] || (_cache[4] = _createTextVNode("camera"))
                      ]),
                      _: 1
                    })
                  ]))
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(CameraIcon, { class: "inline-block w-6 h-6" }),
                _cache[5] || (_cache[5] = _createTextVNode("camera"))
              ])
            ]))
      ])
    ])
  ], 64))
}
}

})